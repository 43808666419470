import {Assignment, CalendarToday, ExitToApp, List as ListIcon, Score, Settings} from "@mui/icons-material";

export default {
    Assignment: Assignment,
    CalendarToday: CalendarToday,
    ExitToApp: ExitToApp,
    Score: Score,
    Settings: Settings,
    Default: ListIcon
};
