// @ts-check
import {List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {arrayOf, bool, func, shape, string} from "prop-types";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {loadSurgeryAssignmentList} from "../../pages/surgery_assignment/surgery_assignment_actions";
import {selectCurrentOrganizationId, selectCurrentUserRights} from "../../redux/app_selectors";
import {PERMISSION} from "../../utils/security";
import componentMapping from "./component_mapping";
import iconMapping from "./icon_mapping";

/**
 * The Menu component is a wrapper that can be used to render menus across the app.
 *
 * @param {object} props
 * @param {{icon: string, listItem: string, iconDefault: string, listItemText: string, listItemIcon: string}} props.classes The classes to style the icons
 * @param {string} props.currentPath pathname of the current url location
 * @param {boolean} [props.iconOnly] Whether the menu shows only icons, or the icons and a small description.
 * @param {Array<MenuStructure>} props.menu A list describing the items of the menu. For an example look at user_menu_structure.js
 * @param {Function} props.onItemClick A handler to go a different route. This route is declare inside the menu param
 * @param {Function} props.cx A function to merge classes
 * @return {React.ReactElement}
 */
const Menu = ({classes, currentPath, iconOnly, menu, onItemClick, cx}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // redux
    const organizationId = useSelector(selectCurrentOrganizationId);
    const permissions = useSelector(selectCurrentUserRights);

    // effects
    useEffect(() => {
        if (organizationId && permissions.includes(PERMISSION.VIEW_SURGERYASSIGNMENT)) {
            dispatch(loadSurgeryAssignmentList());
        }
    }, [organizationId]);

    // handlers
    const handleItemClick = (name, route) => (event) => {
        onItemClick({name, route}, event);
    };

    return (
        <List>
            {menu.map(({component, pages, icon, name, route, translationKey}) => {
                const IconComponent = component ? componentMapping[component] : iconMapping[icon] || iconMapping.Default;
                const isActive = route && currentPath.length > 1 && currentPath.startsWith(route);
                const routeToGo = pages && pages.length ? pages[0].route : route;
                const hasIcon = Boolean(icon);

                return hasIcon ? (
                    <ListItem
                        button
                        className={classes.listItem}
                        component="li"
                        data-testid={`menuItem-${name}`}
                        key={name}
                        selected={isActive}
                        title={iconOnly ? t(translationKey) : ""}
                        onClick={handleItemClick(name, routeToGo)}
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <IconComponent className={cx(classes.icon, classes.iconDefault)} color="inherit" cx={component && cx} />
                        </ListItemIcon>
                        {!iconOnly && <ListItemText className={classes.listItemText} primary={t(translationKey)} />}
                    </ListItem>
                ) : null;
            })}
        </List>
    );
};

Menu.propTypes = {
    classes: shape({
        icon: string,
        listItem: string,
        listItemIcon: string,
        listItemText: string,
        iconDefault: string
    }),
    currentPath: string.isRequired,
    iconOnly: bool,
    menu: arrayOf(
        shape({
            name: string.isRequired,
            translationKey: string.isRequired,
            route: string,
            pages: arrayOf(
                shape({
                    name: string,
                    route: string,
                    translationKey: string,
                    hasBadge: bool
                })
            )
        })
    ).isRequired,
    onItemClick: func.isRequired,
    cx: func.isRequired
};

export default Menu;
