import {makeStyles} from "tss-react/mui";

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme) => {
    const drawerWidth = theme.custom.menuWidth;

    return {
        drawer: {
            "width": drawerWidth,
            "flexShrink": 0,
            "borderLeft": "none",
            "position": "fixed",
            "top": theme.custom.headerHeight,
            "height": `calc(100% - ${theme.custom.headerHeight})`,
            "left": 0,
            "zIndex": theme.zIndex.appBar - 5,
            "& .MuiButton-root:hover": {
                backgroundColor: theme.palette.common.white
            }
        },
        drawerFullWidth: {
            width: "9.25rem"
        },
        drawerWithTeaser: {
            top: "78px",
            height: "calc(100% - 78px)"
        },
        drawerPaper: {
            position: "absolute",
            padding: `${theme.spacing("m")} 0`,
            backgroundColor: theme.palette.common.white,
            border: "none",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            height: "100%",
            boxShadow: theme.shadows[2]
        },
        listWrapper: {
            "backgroundColor": theme.palette.common.white,
            "display": "flex",
            "flexDirection": "column",
            "justifyContent": "space-between",
            "height": "100%",
            "width": "100%",
            "& .MuiList-padding": {
                padding: 0
            }
        },
        listItem: {
            "color": theme.palette.text.primary,
            "display": "flex",
            "padding": `${theme.spacing("xxs")} 0`,
            "&:hover": {
                backgroundColor: theme.palette.common.white
            },
            "&.Mui-selected": {
                backgroundColor: theme.palette.common.white
            },
            "&.Mui-selected:hover": {
                backgroundColor: `${theme.palette.common.white} !important`
            },
            "&.Mui-selected svg": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.light
            }
        },
        listItemIcon: {
            marginLeft: "0.75rem"
        },
        listItemText: {
            "& span": {
                fontSize: "0.75rem",
                color: theme.palette.text.secondary,
                textTransform: "capitalize",
                fontWeight: theme.typography.fontWeightMedium
            }
        },
        icon: {
            "width": "2.5rem",
            "height": "2.5rem",
            "borderRadius": "1.25rem",
            "padding": theme.spacing("s"),
            "&:hover": {
                backgroundColor: theme.palette.primary.light
            }
        },
        label: {
            fontSize: "0.75rem",
            textTransform: "capitalize",
            color: theme.palette.text.secondary
        },
        iconDefault: {
            color: theme.palette.grey[700]
        },
        toggleWrapper: {
            // position: "absolute",
            marginLeft: 0,
            bottom: theme.spacing("s")
        },
        toggleIcon: {
            transform: "matrix(0, 1, -1, 0, 0, 0)",
            color: theme.palette.grey[700]
        },
        fullWidthButton: {
            "width": "9.25rem",
            "padding": theme.spacing("xxs"),
            "& .MuiButton-label": {
                display: "flex",
                justifyContent: "start",
                paddingLeft: "0.75rem"
            }
        },
        minWidthButton: {
            "borderRadius": 0,
            "padding": theme.spacing("xxs"),
            "& .MuiButton-startIcon": {
                margin: 0
            }
        },
        supportPopover: {
            paddingLeft: "0.75rem",
            paddingBottom: "0.5rem"
        }
    };
});
