// @ts-check
import {CheckCircle, Lock} from "@mui/icons-material";
import {Checkbox, FormControlLabel} from "@mui/material";
import {arrayOf, bool, func, string} from "prop-types";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import sortArrayOfObjectsByName from "../../utils/sort_array_of_objects_by_name";
import {loadDisciplineOptionsAction, selectOptions} from "../disciplines";
import {selectDisciplineColors} from "../fe_settings/fe_settings_selectors";
import OpOverlapIcon from "../shared/icons/op_overlap_icon";
import useStyles from "./legend.styles";

export const SLOT_TYPE = {
    ALL: "ALL",
    EMERGENCY: "EMERGENCY"
};

/**
 * Legend component
 * @param {Object} props
 * @param {Object} [props.hasIcons]
 * @param {Object} [props.hasCheckbox]
 * @param {Array<String>} [props.visibleDisciplines] array of healthcareServiceId
 * @param {Function} [props.onToggle]
 * @return {React.ReactElement}
 */
export const Legend = ({hasIcons, hasCheckbox, visibleDisciplines, onToggle}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // Redux
    const organizationId = useSelector(selectCurrentOrganizationId);
    const disciplineColors = useSelector(selectDisciplineColors);
    const disciplines = useSelector(selectOptions);

    useEffect(() => {
        // @ts-ignore @todo jsdoc to be improved
        dispatch(loadDisciplineOptionsAction(organizationId));
    }, []);

    const buildLabel = ({hasCheckbox, id, name, disciplineColor}) => (
        <div className={cx(classes.itemWrapper, {[classes.itemWrapperWithCheckbox]: hasCheckbox})} key={id}>
            <div
                className={classes.dot}
                style={{
                    backgroundColor: disciplineColor ? disciplineColor.hex : "#fff"
                }}
            />
            <div className={classes.item} title={id}>
                {name}
            </div>
        </div>
    );

    const legendIcons = [
        {
            label: t("Footer.medicallyCleared"),
            icon: <CheckCircle className={cx(classes.icon, classes.checkIcon)} />
        },
        {
            label: t("Footer.locked"),
            icon: <Lock className={cx(classes.icon, classes.lockedIcon)} />
        },
        {
            label: t("Footer.overlapped"),
            icon: <OpOverlapIcon style={classes.overlapIcon} />
        }
    ];
    const iconsList = (
        <>
            <div className={cx(classes.divider, classes.mb1)} />
            {legendIcons.map(({label, icon}) => (
                <div className={classes.itemWrapper} key={label}>
                    <div className={classes.iconWrapper}>{icon}</div>
                    <div className={classes.item}> {label}</div>
                </div>
            ))}
        </>
    );
    const labelEmergency = (
        <div className={cx(classes.itemWrapper, {[classes.itemWrapperWithCheckbox]: hasCheckbox})} key={"emergency"}>
            <div className={cx(classes.dot, classes.emergency)} />
            <div className={classes.item} title={t("Legend.emergency")}>
                {t("Legend.emergency")}
            </div>
        </div>
    );
    return (
        <div className={classes.root}>
            <div className={classes.contentWrapper}>
                <div className={classes.title}>{t("Legend.title")}</div>
                <div className={classes.divider} />
                <div className={classes.content}>
                    {hasCheckbox && (
                        <FormControlLabel
                            classes={{label: classes.checkBoxLabel}}
                            control={
                                <Checkbox
                                    checked={visibleDisciplines.includes(SLOT_TYPE.ALL)}
                                    size={"small"}
                                    onClick={() => onToggle(SLOT_TYPE.ALL)}
                                />
                            }
                            label={t("Legend.rooms")}
                        />
                    )}
                    {disciplines
                        .map(({id, name}) => ({id, name: t([`HealthcareService.${id}`, name])}))
                        .sort(sortArrayOfObjectsByName)
                        .map(({id, name}) => {
                            const disciplineColor = disciplineColors.find((item) => item.name === id);
                            const label = buildLabel({hasCheckbox, id, name, disciplineColor});
                            return hasCheckbox ? (
                                <FormControlLabel
                                    classes={{label: classes.checkBoxLabel, root: classes.checkBoxRoot}}
                                    control={
                                        <Checkbox
                                            checked={visibleDisciplines?.includes(id) || visibleDisciplines.includes("ALL")}
                                            size={"small"}
                                            onClick={() => onToggle(id)}
                                        />
                                    }
                                    key={id}
                                    label={label}
                                />
                            ) : (
                                label
                            );
                        })}
                    {!hasCheckbox && <div className={classes.dividerMiddle} />}
                    {hasCheckbox ? (
                        <FormControlLabel
                            classes={{label: classes.checkBoxLabel, root: cx(classes.checkBoxRoot, classes.mb1)}}
                            control={
                                <Checkbox
                                    checked={visibleDisciplines?.includes(SLOT_TYPE.EMERGENCY) || visibleDisciplines.includes("ALL")}
                                    size={"small"}
                                    onClick={() => onToggle(SLOT_TYPE.EMERGENCY)}
                                />
                            }
                            key={"emergency"}
                            label={labelEmergency}
                        />
                    ) : (
                        labelEmergency
                    )}
                    {hasIcons && iconsList}
                </div>
            </div>
        </div>
    );
};

Legend.propTypes = {
    hasIcons: bool,
    hasCheckbox: bool,
    onToggle: func,
    visibleDisciplines: arrayOf(string) // array of healthcareServiceIds + "EMERGENCY"
};
export default Legend;
