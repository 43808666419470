// @ts-check
import {makeStyles} from "tss-react/mui";

export default makeStyles()((/** @type AllCustomTheme */ theme) => ({
    root: {
        color: theme.palette.grey[900],
        width: "100%",
        position: "relative"
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
        position: "relative"
    },
    title: {
        ...theme.custom.rightPanel.title
    },
    divider: {
        ...theme.custom.rightPanel.divider
    },
    dividerMiddle: {
        ...theme.custom.rightPanel.divider,
        margin: "0.5rem 0"
    },
    content: {
        padding: "2.5rem",
        overflow: "auto",
        height: "calc(100% - 190px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch"
    },
    dot: {
        width: "1rem",
        height: "1rem",
        overflow: "hidden",
        borderRadius: "0.5rem"
    },
    iconWrapper: {
        display: "flex",
        justifyContent: "center",
        width: "22px"
    },
    emergency: {
        backgroundColor: theme.custom.emergency.main
    },
    item: {
        fontSize: "0.875rem",
        color: theme.palette.grey[700],
        paddingLeft: "0.55rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    itemWrapper: {
        position: "relative",
        display: "flex",
        lineHeight: "3rem",
        alignItems: "center"
    },
    itemWrapperWithCheckbox: {
        lineHeight: "1.5rem"
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        margin: `1.5rem 2.5rem`,
        position: "absolute",
        bottom: 0,
        width: "calc(100% - 5rem)"
    },
    icon: {
        width: "18px",
        height: "18px",
        background: "#f7f7f7",
        borderRadius: "50%"
    },
    checkBoxLabel: {
        fontSize: "0.875rem",
        color: theme.palette.grey[700],
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    checkBoxRoot: {
        marginLeft: theme.spacing("m")
    },

    mb1: {
        marginBottom: theme.spacing("m")
    },
    checkIcon: {
        color: theme.palette.success.main
    },
    lockedIcon: {
        color: theme.palette.text.secondary
    },
    overlapIcon: {
        width: "22px",
        height: "22px",
        background: "#f7f7f7",
        borderRadius: "50%"
    }
}));
